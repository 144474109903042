<template>
  <div class="reset-password">
    <div class="container">
      <div class="reset-password-card">
        <div class="title">Change Password</div>

        <ValidationObserver ref="observer"
                            v-slot="{ passes }"
                            slim>
          <form class="mb-2"
                @submit.prevent="passes(runCaptcha)">
            <base-textbox label="New Password"
                          ref="password"
                          type="password"
                          rules="required|min:6"
                          v-model="password"
                          :show-password-toggle="true"
                          placeholder="Enter new password" />
              <base-button type="submit"
                           class="submit-button"
                           color="primary"
                           size="big"
                           :loading="loading">Change</base-button>
          </form>
        </ValidationObserver>

        <base-alert v-if="showError"
                    type="error">
          Password reset failed. Probably the link has expired,
          <router-link :to="{ name: 'forgotPassword' }">try again</router-link>
        </base-alert>
        <vue-recaptcha ref="recaptcha"
                       size="invisible"
                       :sitekey="recaptchaKey"
                       @verify="submitForm"
                       @expired="onCaptchaExpired" />
      </div>
      <recaptcha-disclaimer />
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import RecaptchaDisclaimer from '@/components/Auth/AuthRecaptchaDisclaimer.vue'
import auth from '@/services/auth.js'

export default {
  components: {
    VueRecaptcha,
    RecaptchaDisclaimer
  },

  metaInfo: {
    title: 'Reset Password'
  },

  data () {
    return {
      password: '',
      token: this.$route.query.token,
      recaptcha_token: null,

      showError: false,
      loading: false,

      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    }
  },

  mounted () {
    if (!this.$route.query.token) {
      this.$router.push({ name: 'forgotPassword' })
    }

    this.$refs.password.focus()
  },

  methods: {
    runCaptcha () {
      this.loading = true
      this.$refs.recaptcha.execute()
    },

    async submitForm (recaptcha_token) {
      const success = await auth.resetPassword({
        token: this.token,
        password: this.password,
        recaptcha_token
      })

      this.loading = false

      if (!success) {
        this.showError = true
        return
      }

      this.$router.push({
        name: 'login',
        query: {
          passwordReset: 1
        }
      })
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
  .reset-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);

    .container {
      max-width: 500px;
    }

    @media (max-width: 768px) {
      .container {
        width: 100%;
        max-width: none;
      }
    }

    .reset-password-card {
      padding: 40px;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 5px 5px 20px 0 rgba(36, 50, 66, 0.1);
    }

    .submit-button {
      width: 200px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 14px;

      font-size: 20px;
      font-weight: 700;
    }

    .tools {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
  }
</style>
